export const API_ROOT = process.env.API_ROOT;
export const OAUTH_ROOT = process.env.OAUTH_ROOT;
export const OAUTH_CREDENTIALS = {
  CLIENT_ID: process.env.OAUTH_ID,
  CLIENT_SECRET: process.env.OAUTH_SECRET
}
export const AUTH_STATES = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILED: 'failed'
}

export const ERROR_FIELD_ID = {
  '.loopUrl': 'loopUrl',
  '.senderEmail': 'senderEmail',
  '.contactEmail': 'contactEmail',
  '.recipientEmail': 'recipientEmail'
}

export const ERROR_FIELD_NAME = {
  '.loopUrl': 'Gift URL',
  '.senderEmail': 'Sender Email',
  '.contactEmail': 'Contact Email',
  '.recipientEmail': 'Recipient Email'
}

export const CONTACT_INFO = {
  babytula: {
    homePageUrl: 'https://babytula.com',
    logoUrl: 'https://static.loopassets.net/store/babytula/images/logo.svg',
    storeName: 'Baby Tula'
  },
  barcelonawinebar: {
    homePageUrl: 'https://www.barcelonawinebar.com',
    logoUrl: 'https://static.loopassets.net/store/barcelonawinebar/images/logo.png',
    storeName: 'Barcelona Wine Bar'
  },
  birkenstock: {
    homePageUrl: 'https://www.birkenstock.com/us',
    logoUrl: 'https://static.loopassets.net/store/birkenstock/images/logo-v3.svg',
    storeName: 'Birkenstock'
  },
  catbirdnyc: {
    homePageUrl: 'https://www.catbirdnyc.com',
    logoUrl: 'https://static.loopassets.net/store/catbirdnyc/images/logo-v2.png',
    storeName: 'Catbird'
  },
  coach: {
    homePageUrl: 'https://www.coach.com',
    logoUrl: 'https://static.loopassets.net/store/coach/images/coach_logo_black.png',
    storeName: 'Coach'
  },
  coachoutlet: {
    homePageUrl: 'https://www.coachoutlet.com',
    logoUrl: 'https://static.loopassets.net/store/coachoutlet/images/Logo.svg',
    storeName: 'Coach Outlet'
  },
  crocs: {
    homePageUrl: 'https://www.crocs.com',
    logoUrl: 'https://static.loopassets.net/store/crocs/images/crocs-logo.svg',
    storeName: 'Crocs'
  },
  davidyurman: {
    homePageUrl: 'https://www.davidyurman.com',
    logoUrl: 'https://static.loopassets.net/store/davidyurman/images/v3/logo-trim-2x.png',
    storeName: 'David Yurman'
  },
  doversaddlery: {
    homePageUrl: 'https://www.doversaddlery.com/',
    logoUrl: 'https://static.loopassets.net/store/doversaddlery/images/logo.svg',
    storeName: 'Dover Saddlery'
  },
  dressageextensions: {
    homePageUrl: 'https://www.dressageextensions.com/',
    logoUrl: 'https://static.loopassets.net/store/dressageextensions/images/logo.png',
    storeName: 'Dressage Extensions'
  },
  duckdonuts: {
    homePageUrl: 'https://www.duckdonuts.com',
    logoUrl: 'https://static.loopassets.net/store/duckdonuts/images/logo.png',
    storeName: 'Duck Donuts'
  },
  elfcosmetics: {
    homePageUrl: 'https://www.elfcosmetics.com',
    logoUrl: 'https://static.loopassets.net/store/elfcosmetics/images/Logo.svg',
    storeName: 'Elf Cosmetics'
  },
  evo: {
    homePageUrl: 'https://www.evocinemas.com',
    logoUrl: 'https://static.loopassets.net/store/evo/images/logo-blue.png',
    storeName: 'EVO'
  },
  fahertybrand: {
    homePageUrl: 'https://fahertybrand.com/',
    logoUrl: 'https://static.loopassets.net/store/fahertybrand/images/logo.png',
    storeName: 'Faherty Brand'
  },
  homechef: {
    homePageUrl: 'https://www.homechef.com',
    logoUrl: 'https://static.loopassets.net/store/homechef/images/logo.svg',
    storeName: 'Home Chef'
  },
  katespade: {
    homePageUrl: 'https://www.katespade.com',
    logoUrl: 'https://static.loopassets.net/store/katespade/images/toro_platform/logo.jpeg',
    storeName: 'Kate Spade'
  },
  keyssoulcare: {
    homePageUrl: 'https://www.keyssoulcare.com',
    logoUrl: 'https://static.loopassets.net/store/keyssoulcare/images/logo.png',
    storeName: 'Keys Soulcare'
  },
  madeincookware: {
    homePageUrl: 'https://madeincookware.com',
    logoUrl: 'https://static.loopassets.net/store/madeincookware/images/logo_black.png',
    storeName: 'Made In'
  },
  michaelkors: {
    homePageUrl: 'https://www.michaelkors.com',
    logoUrl: 'https://static.loopassets.net/store/michaelkors/images/logo.png',
    storeName: 'Michael Kors'
  },
  mizzenandmain: {
    homePageUrl: 'https://www.mizzenandmain.com',
    logoUrl: 'https://static.loopassets.net/store/mizzenandmain/images/logo-v2-2x.png',
    storeName: 'Mizzen+Main'
  },
  nixon: {
    homePageUrl: 'https://www.nixon.com/',
    logoUrl: 'https://static.loopassets.net/store/nixon/images/logo.png',
    storeName: 'Nixon'
  },
  peets: {
    homePageUrl: 'https://www.peets.com',
    logoUrl: 'https://static.loopassets.net/store/peets/images/Logo-Peets-Horizontal-Dark.png',
    storeName: `Peet's Coffee`
  },
  synchronyvaultshop: {
    homePageUrl: 'https://synchronyprivatelabel.com',
    logoUrl: 'https://static.loopassets.net/store/synchronyvaultshop/images/logo.png',
    storeName: 'Synchrony Vault Shop'
  },
  taylormadegolf: {
    homePageUrl: 'https://www.taylormadegolf.com',
    logoUrl: 'https://static.loopassets.net/store/taylormadegolf/images/logo-v2.png',
    storeName: 'TaylorMade'
  },
  thriftbooks: {
    homePageUrl: 'https://www.thriftbooks.com',
    logoUrl: 'https://static.loopassets.net/store/thriftbooks/images/logo.png',
    storeName: 'ThriftBooks'
  },
  tovala: {
    homePageUrl: 'https://www.tovala.com/',
    logoUrl: 'https://static.loopassets.net/store/tovala/images/logo.svg',
    storeName: 'Tovala'
  },
  verabradley: {
    homePageUrl: 'https://verabradley.com',
    logoUrl: 'https://static.loopassets.net/store/verabradley/images/logo-main-gray.svg',
    storeName: 'Vera Bradley'
  },
  wellpeople: {
    homePageUrl: 'https://www.wellpeople.com',
    logoUrl: 'https://static.loopassets.net/store/wellpeople/images/logo.png',
    storeName: 'Well People'
  },
  westernrider: {
    homePageUrl: 'https://www.westernrider.com',
    logoUrl: 'https://static.loopassets.net/store/westernrider/images/logo.png',
    storeName: 'Western Rider'
  },
  uniqlo: {
    homePageUrl: 'https://faq-us.uniqlo.com/articles/en_US/FAQ/Gifts-Gift-Card/',
    logoUrl: 'https://static.loopassets.net/store/uniqlo/images/logo.png',
    storeName: 'Uniqlo'
  }
}

export const FAQS = [
  {
    question: 'What happens if my recipient did not claim their gift?',
    answer: `If a product e-gift was purchased and not claimed within 90 days, it is automatically converted into a gift card. If you had chosen to deliver the e-gift by email, the recipient will receive an email with a link to get their gift card after 90 days. If you had chosen to deliver the e-gift by text message or print and hand-deliver, the gift card will be emailed to the address you used when purchasing the gift. Gift cards do not expire, so if a gift was not claimed, the gift card balance is available until it is used to make a purchase within the store. We strongly recommend recipients save and print their gift card details.`
  },
  {
    question: 'What will happen to the gift cards I purchased through GiftNow?',
    answer: `Gift cards do not expire, so if a gift was not claimed, the gift card balance is available until it is used to make a purchase within the store. We strongly recommend recipients save and print their gift card details.`
  },
  {
    question: 'Can I get a refund for any outstanding GiftNow balances or unused gift cards?',
    answer: 'Refunds are not available for gifts and gift cards purchased using GiftNow.'
  },
  {
    question: 'Can I still send gifts to my recipients using GiftNow?',
    answer: `New gifts can no longer be sent to recipients using GiftNow.`
  }
]
