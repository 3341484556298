import React, {useState, useEffect} from 'react';

import { useLocation } from 'react-router-dom'

import { ContactForm } from './components/ContactForm';
import { ErrorPage } from './components/Error';
import { getAuthToken } from './api';
import { Loader } from 'semantic-ui-react';


export const PKCEFlow = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  let location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const code = searchParams?.get('code');;
    if (!code) {
      setIsError(true)
    } else {
      getToken(code)

    }
    async function getToken(c) {
      try {
        const token = await getAuthToken(c)
        const { access_token, issued_at, expires_in } = token.data;
        localStorage.setItem('authToken', JSON.stringify({
          token: access_token,
          expiry: Number(issued_at) + Number(expires_in)
        }))
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
      }
    }
  }, [location])

  if (isError) {
    return (
      <ErrorPage />
    )
  }

  return (
    <>
      {isLoading ? (
        <Loader active/>
      ) : (
        <ContactForm />
      )}
    </>
  )
}