import React, {useState} from 'react';
import { Form, Button, Message, Input } from 'semantic-ui-react';
import { GiftNowHeadline } from '../Headline';
import { isEmail } from '../../utils/validations';
import { submitGiftInfo, submitGiftDetails } from '../../api';
import { ERROR_FIELD_NAME, ERROR_FIELD_ID } from '../../constants';

import './styles.scss';

export const ContactForm = () => {
  const [loopUrl, setLoopUrl] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [formErrors, setFormErrors] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidFields, setInvalidFields] = useState({});

  const handleInputChange = (e, updateFunc) => {
    let field = e.target.name;
    let updatedInvalidFields = {
      ...invalidFields,
      [field]: false
    }
    setInvalidFields(updatedInvalidFields);
    updateFunc(e.target.value);
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    clearForm();
    const errors = validateForm();
    if (!errors.length){
        submitPayload();
    } else {
        handleErrors(errors);
    }
  }

  const handleErrors = (errors) => {
    if (errors.length) {
      const invalidFields = {};
      errors.forEach(error => {
        invalidFields[error.id] = true;
      })
      setFormErrors(errors);
      setInvalidFields(invalidFields);
    }
  }

  const validateForm = () => {
    const errors = [];
    if (!loopUrl) { errors.push({id: "loopUrl", msg: "Please enter a valid Claim URL" }) }
    if (senderEmail && !isEmail(senderEmail)){ errors.push({ id: "senderEmail", msg: "Please enter a valid Sender Email" }) }
    if (recipientEmail && !isEmail(recipientEmail)){ errors.push({ id: "recipientEmail", msg: "Please enter a valid Recipient Email" }) }
    if (!isEmail(contactEmail)){ errors.push({ id: "contactEmail", msg: "Please enter a valid Contact email" }) }
    return errors;
  }

  const submitPayload = async () => {
    let data = {
      loopUrl: loopUrl,
      contactEmail,
    };

    if (senderEmail) {
      data.senderEmail = senderEmail;
    }
    if (recipientEmail) {
      data.recipientEmail = recipientEmail;
    }
    // make POST request to submit data
    setIsLoading(true);
    console.log("submitting data...", data);
    try {
      const response = await submitGiftInfo(data);
      await submitGiftDetails(data);
      if (response.status === 201 || response.status === 200){
        // show success submit 
        console.log("Form submit successful")
        handleSuccess()
      } else {
        // show submit fail
        console.error('Submission error')
      }
    } catch (e) {
      // show error
      let formErrors = [];
      const errors = e?.response?.data?.errors
      if (errors) {
        formErrors = errors.map(error => {
          const { field, reason, location } = error;
          const fieldId = ERROR_FIELD_ID[field];
          return {
            id: fieldId,
            msg: `${ERROR_FIELD_NAME[field]}: ${reason}`
          };
        })
      } else {
        formErrors = [{
          id: 'Generic',
          msg: 'There was an issue with your request.  Please try again later.'
        }];
      }

      handleErrors(formErrors);
      console.error(e);
    } finally {
      setIsLoading(false);
    }
    
  }

  const handleSuccess = () => {
    handleErrors([]);
    setIsSuccess(true);
    setLoopUrl('');
    setRecipientEmail('');
    setSenderEmail('');
    setContactEmail('');
  }

  const clearForm = () => {
    setFormErrors([]);
    setIsSuccess(false);
  }

  const hasErrors = formErrors.length > 0;

  return (
    <>
      <GiftNowHeadline/>
      <Form onSubmit={handleFormSubmit} error={hasErrors} success={isSuccess} loading={isLoading} className="contact-form">
        <Message
          error
          header="An error has occurred"
          content={(
            <ul>
              {formErrors.map((error) => <li key={`error-${error.id}`}>{error.msg}</li>)}
            </ul>
          )}
        />
        <Message
          success
          header="Thank you for your submission"
          content="A member of the support staff will be looking into your inquiry and contact you back as soon as possible."
        />
        <Form.Field
          control={Input}
          id="form-claim-url"
          name="loopUrl"
          label="Gift URL"
          value={loopUrl}
          onChange={(e) => handleInputChange(e, setLoopUrl)}
          error={invalidFields["loopUrl"]}
          required
        />
        <Form.Field
          control={Input}
          id="form-sender-email"
          label={"Sender Email"} 
          name={'senderEmail'} 
          value={senderEmail} 
          onChange={(e) => handleInputChange(e, setSenderEmail)}
          error={invalidFields["senderEmail"]}
        />
        <Form.Field
          control={Input}
          id="form-recipient-email"
          label={"Recipient Email"} 
          name={'recipientEmail'} 
          value={recipientEmail} 
          onChange={(e) => handleInputChange(e, setRecipientEmail)}
          error={invalidFields["recipientEmail"]}
        />
        <Form.Field
          control={Input}
          id="form-contact-email"
          label={"Contact Email"} 
          name={'contactEmail'} 
          value={contactEmail} 
          onChange={(e) => handleInputChange(e, setContactEmail)}
          error={invalidFields["contactEmail"]}
          required
        />
        <Button type="submit">Submit</Button>
      </Form>
    </>
  )
}