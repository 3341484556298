import React from 'react';
import { Loader } from 'semantic-ui-react';
import { useAuth } from './hooks/useAuth';
import { AUTH_STATES } from './constants';
import { ErrorPage } from './components/Error';

export const Auth = ({ children }) => {
  const {status: authStatus} = useAuth();
  return (
    <>
      {authStatus === AUTH_STATES.LOADING ? (
        <Loader active/>
      ) : (
        <>
          {authStatus === AUTH_STATES.SUCCESS ? (
            <>
              {children}
            </>
          ): (
            <ErrorPage/>
          )}
        </>
      )}
    </>
  )
} 