import './styles.scss';
import React from 'react';
import { Segment, Image, Header as SemanticHeader, Grid } from 'semantic-ui-react';
import logo from '../../../public/logo.png';

export const Header = () => {
  return (
    <Segment inverted className="header-container">
        <div className="logo">
          <Image src={logo}  alt="logo"/>
        </div>
        <div className="header-content">
          <SemanticHeader>Synchrony Support</SemanticHeader>
        </div>
    </Segment>
  );
}