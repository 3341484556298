import axios from 'axios';
import { API_ROOT } from '../../constants';
import { getTokenFromStorage } from '../auth';
import { v4 as uuidv4 } from 'uuid';

const trackingId = uuidv4();

function submitGiftInfo(payload) {
  const token = getTokenFromStorage();
  return axios.post(`${API_ROOT}/gift-info`, payload, {
    headers: {
      'X-SYF-Request-TrackingId': trackingId,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
}

function submitGiftDetails(payload) {
  const token = getTokenFromStorage();
  return axios.post(`${API_ROOT}/gift-details`, payload, {
    headers: {
      'X-SYF-Request-TrackingId': trackingId,
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
}

export {
  submitGiftInfo,
  submitGiftDetails
}