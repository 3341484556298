import React from 'react';
import { GiftNowHeadline } from '../Headline';
import './styles.scss'

export const ContactPage = ({ store }) => {
  const { homePageUrl, storeName } = store;
  return (
    <>
      <GiftNowHeadline isFirstParty={true} storeName={storeName}/>
      <div className="contact-page-link">
        To contact {storeName} please click <a href={homePageUrl}>here</a>
      </div>
    </>
  )
}