import React, { useEffect, useState } from 'react';
import { getAuthorization } from '../../api';
import { AUTH_STATES } from '../../constants';
import { useHistory } from 'react-router-dom';

export function useAuth() {

  const [status, setStatus] = useState(AUTH_STATES.LOADING)

  const history = useHistory();

  useEffect(() => {
    async function getToken() {
      try {
        const response = await getAuthorization();
        let url = new URL(response.request.responseURL);
        const code = url.searchParams.get('code');
        history.push({
          pathname: '/verified',
          search: `?code=${code}`
        })
        setStatus(AUTH_STATES.SUCCESS);
      } catch (e) {
        console.error('An error occurred while trying to retrieve an oauth token', e);
        setStatus(AUTH_STATES.FAILED);
      }
    }
    getToken();
  }, [])

  return {
    status
  };
}