import React, { useState } from 'react';
import { Accordion, Icon, Header } from 'semantic-ui-react';
import { FAQS } from '../../constants'; 
import './styles.scss'

export const AccordionFAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index);
    }
  }

  return (
    <div className="faq-container">
      <Header as="h2">Frequently asked questions</Header>
      <Accordion className="faq-content">
        {FAQS.map((faq, i) => (
          <div key={faq.question} className="faq-item">
            <Accordion.Title
              active={activeIndex === i}
              index={i}
              onClick={() => handleClick(i)}
              className="faq-title"
            >
              <h4 className="faq-question">
                {faq.question}
              </h4>
              {
                activeIndex === i ? (
                  <Icon name="minus"/>
                ) : (
                  <Icon name="plus"/>
                )
              }
            </Accordion.Title>
            <Accordion.Content active={activeIndex === i}>
              <p className="faq-answer">
                {faq.answer}
              </p>
            </Accordion.Content>
          </div>
        ))}
      </Accordion>
    </div>
  )
} 