import React, {useState, useEffect} from 'react';
import { Container, Loader } from 'semantic-ui-react';
import { Routes, Route, Switch } from 'react-router-dom'
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { ContactPage } from './components/ContactPage';
import { AccordionFAQs } from './components/Accordion';
import { CONTACT_INFO } from './constants';
import { Auth } from './Auth';
import { PKCEFlow } from './Pkce';

import './styles.css';

export const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [store, setStore] = useState({})

  useEffect(() => {
    const host = window.location.host;
    const [storeShortName] = host.split('.');
    const parsedStoreShortName = storeShortName.replace('qa-', '');
    const store = CONTACT_INFO[parsedStoreShortName];
    if (store) {
      setStore(store);
    }
    setIsLoading(false);
  }, [])

  const isFirstParty = Object.entries(store).length !== 0;

  return (
    <>
      <Header/>
      <Container className="customer-wrapper">
        <div className="content-section">
          {
            isLoading ? (
              <Loader active={isLoading}>Loading</Loader>
            ) : (
              isFirstParty ? (
                <ContactPage store={store} />
              ) : (
                <>
                  <Route path="/"><Auth /></Route>
                  <Route path="/verified"><PKCEFlow/></Route>
                </>
              )
            )
          }
        </div>
      </Container>
      <div className="faq-section">
        <Container>
          <AccordionFAQs/>
        </Container>
      </div>
      <Footer/>
    </>
  )
}