import './styles.scss';
import React from 'react';
import { Segment, Header } from 'semantic-ui-react';

export const Footer = () => { 
  return (
    <Segment inverted className="footer-container">
      <Header as='h5'>Contact Us | {'\u00A9'}2023 Synchrony </Header>
    </Segment>
  )
}