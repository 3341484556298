import axios from 'axios';
import { OAUTH_ROOT, OAUTH_CREDENTIALS } from '../../constants';
import { generateRandomString, pkceChallengeFromVerifier } from '../../utils/token';
import { v4 as uuidv4 } from 'uuid';

const verifier = generateRandomString()
const code_challenge = await pkceChallengeFromVerifier(verifier)
const userId = uuidv4();
const redirect_uri = window.location.href;

async function getAuthorization() {
  const params = new URLSearchParams();
  params.append('response_type', 'code');
  params.append('client_id', OAUTH_CREDENTIALS.CLIENT_ID);
  params.append('code_challenge', code_challenge);
  params.append('code_challenge_method', 'S256');
  params.append('redirect_uri', redirect_uri);

  return axios.get(`${OAUTH_ROOT}/v1/oauth2/authorize`, { 
    params, 
    headers: {
      'Content-Type' : 'application/x-www-form-urlencoded',
      'X-SYF-UserId': userId
    }
  });
}

async function getAuthToken(c) {
  return axios.post(`${OAUTH_ROOT}/v1/oauth2/token`, {
    grant_type: 'authorization_code',
    client_id: OAUTH_CREDENTIALS.CLIENT_ID,
    code_verifier: verifier,
    code: c,
    redirect_uri: redirect_uri
  }, {
    headers: {
      'Content-Type' : 'application/x-www-form-urlencoded',
    }
  });
}

function getTokenFromStorage() {
  const storage = JSON.parse(localStorage.getItem('authToken'));
  if (storage) {
    return storage.token;
  } else {
    return null;
  }
}

export {
  getAuthorization,
  getAuthToken,
  getTokenFromStorage
}