const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export function isEmail(email){
  let isValid = emailRegex.test(email);
  // If email passes regex, check username and domain pieces separately for length
  // https://www.rfc-editor.org/rfc/rfc5321#section-4.5.3
  if (isValid){
    let matches = email.match(emailRegex);
    let username = matches[1]; // 1st capture group
    let domain = matches[5]; // 5th capture group
    isValid = username?.length <= 64 && domain?.length <= 255;
  }
  if (!isValid) return false;
  return true;
}

export function isPhoneNumber(phone){
  const isPhone = phoneRegex.test(phone);
  if (!isPhone) return false;
  return true;
}