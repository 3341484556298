import React from 'react';
import { Header } from 'semantic-ui-react';
import './styles.scss'

export const GiftNowHeadline = ({ isFirstParty, storeName }) => (
  <div className="giftnow-headline">
    <Header as="h2">Important Update for GiftNow Users</Header>
    <div>
      {`We want to inform you that GiftNow has shut down. If you need to request information about your gift card, please ${isFirstParty ? `contact ${storeName} directly by clicking the link below.` : 'submit the form below.'}`}
    </div>
    <br/>
    <div>We thank everyone who supported GiftNow and used our services to send gifts to friends and family!</div>
  </div>
)